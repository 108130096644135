// src/App.js
import React from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import FeaturedPitches from './components/FeaturedPitches/FeaturedPitches';
// import TestimonialCarousel from './components/Testimonial/TestimonialCarousel';
import Benefits from './components/Benefits/Benefits';
import Footer from './components/Footer/Footer'
import Pitches from './components/Pitches/Pitches';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import JobSeeker from './components/JobSeeker/JobSeeker.jsx';
import Separator from './components/Separator/Separator';
import GetStarted from './components/GetStarted/GetStarted';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Separator />
                  <Benefits />
                  <FeaturedPitches />
                  {/* <TestimonialCarousel /> */}
                  <GetStarted />
                </>
              }
            />
            <Route path="/pitches" element={<Pitches />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile/:userId" element={<JobSeeker />} />{' '}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
